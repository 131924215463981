@import 'utils.scss';

.container {
  display: flex;
  flex-flow: column nowrap;
  width: 440px;
  height: 100%;
  margin: 0 auto;

  background-color: var(--game-wrap-bg-color);
  border-radius: var(--block-gap);
  box-shadow: inset 0 0 10px #fff;

  .header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    padding-top: calc(var(--block-gap) * 2);
    .display {
      display: flex;
      flex-flow: column nowrap;
      border: 3px solid var(--black);
      flex: 1;

      .title {
        justify-self: center;
        display: inline-block;
        transform: translateY(-65%);
        background-color: var(--game-wrap-bg-color);
        margin: 0 auto;
        padding-left: 5px;
        padding-right: 5px;
      }

      .screen {
        height: 478px;
      }
    }

    
    .left,
    .right {
      padding: var(--block-gap);
    }
  }

 
}