@import 'utils.scss';

.container {
  position: absolute;
  left: 20px;
  top: 20px;
  @include use-media-query('phone') {
    top: 0;
    opacity: 0.4;
  }
  pointer-events: none;
  a {
    pointer-events: all;
  }
}